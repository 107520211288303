import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const projects = [
  {
    name: "mujik",
    route: "mujik",
    images: ["mujik.png"],
    tech: ["React + Hooks", "Go", "Fiber", "MongoDB", "Redis"],
  },
  {
    name: "Laundry View",
    images: ["laundryview.png"],
    route: "laundryview",
    tech: ["Kotlin", "Swift", "AWS DynamoDB", "AWS Lambda"],
  },
  {
    name: "Print Stats",
    route: "printstats",
    images: ["printstats.png"],
    tech: ["Vue.js", ".NET Core", "Entity Framework", "RabbitMQ", "PostgreSQL"],
  },
  // {
  //   name: "Spark",
  //   route: "spark",
  //   images: ["laundryview.png"],
  //   tech: ["Go", "Apache Kafka", "Apache Cassandra", "Websocket"],
  // },
  // {
  //   name: "UI Wireframer",
  //   route: "wireframer",
  //   images: ["laundryview.png"],
  //   tech: ["React", "MongoDB"],
  // },
  // {
  //   name: "Chef Wolfie",
  //   route: "/chefwolfie",
  //   images: ["chefwolfie.png"],
  // },
]

class ProjectsPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Projects" />
        {/* <h1>Dev</h1> */}
        <p>
          These some of my personal projects that I work(ed) on during my free
          time. Most of them were created to solve a problem myself and others
          faced. Whereas others were created for me to learn and explore new
          technologies.
        </p>

        <div className="project-cards-container">
          {projects.map(p => {
            return (
              // <div className="project-card">
              // <h4>{p.name}</h4>
              <Link to={`/dev/${p.route}`}>
                <img
                  className="project-card"
                  src={`/projects/${p.route}/${p.images[0]}`}
                  alt={p.name}
                ></img>
              </Link>
              // </div>
            )
          })}
          <div className="project-card-im"></div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
